import React from 'react'
import { StaticQuery, graphql } from "gatsby";

import { Map as GoogleMap, GoogleApiWrapper } from 'google-maps-react'
import MapFilters from '../map/mapFilters.jsx'

const styles = require('../../utils/mapStyles.json')

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      center:[],
      zoom:12,
      markers:[{"name":"Santo Domingo Motors",
                "types":["Concesionario de automóviles"],
                "geometry":{
                    "location":{
                      "lat":() => { return  parseFloat(18.4833135)},
                      "lng":() => { return  parseFloat(-69.9410299)}
                    }
                  },
                "icon":"https://maps.gstatic.com/mapfiles/place_api/icons/geocode-71.png",
                "vicinity": "Santo Domingo Motors"
    }],
      filters: {
        location: {},
        radius: '2000',
        type: ['Dealers'],
        keyword:"Santo Domingo Motors"
      }
    }

    this.handleFilter = this.handleFilter.bind(this)
  }

  componentDidMount() {
    this.mapRender()
  }

  Permission_Requierd(){
    console.log("you denied me :-(");
    //{lat:18.7357,lng:-70.1627} ruta de republica dominicana y zoom 8 que es para apuntar a un pais
    const GoogleApi = this.props.google;

   var map = new GoogleApi.maps.Map(document.getElementById('map').children[0], {
      zoom:14,
      center:{lat:18.4833135,lng:-69.9410299},
      styles: styles.map,
      markers:{lat:18.4833135,lng:-69.9410299}
    })

     var marker = new GoogleApi.maps.Marker({
          position: {lat:18.4833135,lng:-69.9410299},
          map: map,
          name: 'Chevrolet',
          vicinity: 'Chevrolet'
        });

     return marker;
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    const { filters } = this.state

    if( snapshot !== null ) {
      if( filters.type !== prevState.filters.type ) {
        this.mapRender()
      }
    }
  }

  handleFilter(event) {

    var dealer = {e:event};
    console.log(dealer)
   let  location =   [dealer.e.lat , dealer.e.long];


    this.setState( prevState => ({
        markers:[{"name":dealer.e.nombre,
                "types":["Dealers"],
                "geometry":{
                    "location":{
                      "lat":() => { return  parseFloat(dealer.e.lat)},
                      "lng":() => { return  parseFloat(dealer.e.long)}
                    }
                  },
                "icon":"https://maps.gstatic.com/mapfiles/place_api/icons/geocode-71.png",
                "vicinity": dealer.e.nombre
    }],
        center:location
    }))
        this.mapRender()
  }

 mapRender() {
    let parent = this


    navigator.geolocation.getCurrentPosition(function(position) {
      parent.setState(prevState => ({
        filters: {
          ...prevState.filters,
          location: { lat: position.coords.latitude, lng: position.coords.longitude}
        },
        center: { lat: position.coords.latitude, lng: position.coords.longitude},
        zoom:14
      }), function() {

        const { center, zoom, filters } = parent.state

        const GoogleApi = parent.props.google;

        const SubAgentMarkers = parent.state.markers;
        var map = new GoogleApi.maps.Map(document.getElementById('map').children[0], {
            zoom,
            center,
            styles: styles.map
        }),
        UserRequest = filters,

        CircleOptions = {
          strokeColor: '#00A44F',
          strokeOpacity: 0.1,
          strokeWeight: 2,
          fillColor: '#00A44F',
          fillOpacity: 0.075,
          map: map,
          center,
          radius: 3000
        };

        let branchOffices = [];
        let infowindow = new GoogleApi.maps.InfoWindow();
        new GoogleApi.maps.Circle(CircleOptions);
        //Service for the search
        let service = new GoogleApi.maps.places.PlacesService(map);
        service.nearbySearch(UserRequest, PlacesMarker);


        //
        function PlacesMarker(results, status) {


          if (status === GoogleApi.maps.places.PlacesServiceStatus.OK) {

            if (SubAgentMarkers.length > 0) {
                results = [];
                SubAgentMarkers.map( (sbmarker,index)=> results.push(sbmarker));
              }



            var Generalmarker;
            var place;
            let position = {lat:results[0].geometry.location.lat(),lng:results[0].geometry.location.lng()};

             map.setZoom(14);
             map.setCenter(position);

            for (var i = 0; i < results.length; i++) {
              place = results[i];
              position = {lat:place.geometry.location.lat(),lng:place.geometry.location.lng()};
              branchOffices.push([place.name,place.geometry.location, place.website, place.rating]);
              Generalmarker = new GoogleApi.maps.Marker({
                position: position,
                clickable: true,
                map,
                title: place['name']

              });
            }


            GoogleApi.maps.event.addListener(Generalmarker, 'click', function() {
              infowindow.setContent(place.name);
              infowindow.open(map, this);
              map.setZoom(16);
              map.setCenter(position);
            });
          }

        }
      })
    }, function(error) {
      if (error.code === error.PERMISSION_DENIED)
        parent.Permission_Requierd();
    })

  }

  render(){

    const { google } = this.state

    return (
      <StaticQuery
       query={graphql`
            query  {
                    chevrolet{
                      page(id:"cGFnZTozNzk=") {
                        id
                        title
                        concesionarios(first:100) {
                          edges {
                            node {
                              como_llegar
                              contacto
                              descripcion
                              direccion
                              lat
                              logo
                              long
                              nombre

                              }
                            }
                          }
                        }
                      }
                    }
          `}
          render={data => {
          return (

          <div className="uk-container-expand uk-padding-large">
              <div className="uk-grid uk-grid-match uk-flex" uk-grid="true">

                  <div id="MapFilter" className="uk-width-1-4@m uk-width-1-1@s">
                    <form id="MapFormfilter" className="uk-margin-large">
                      <MapFilters concesionarioList={data.chevrolet.page.concesionarios.edges} onFilterClick={ this.handleFilter } />
                    </form>
                  </div>
                  <div className="uk-width-expand@m uk-width-1-1@s uk-height-1-1">
                    <div id="map">
                      <GoogleMap google={ google } zoom={14}/>
                    </div>
                  </div>

              </div>
          </div>

            )
          }
        }
        />
    )
  }}


export  default GoogleApiWrapper({
  apiKey: "AIzaSyBomri_2uPruzi7Fbu7tDGR_mhu_rDHbI0"
})(Map)



