/* eslint-disable */
import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import shadowncard from "../../assets/img/default.png"
import SimpleMap from "../Maps/Map.js"
const config = require("../../../config/app")
import "./fornt.css"

class Contacto extends React.Component {
  constructor(props) {
    super(props)
  }
  state = {
    status: "init",
    filters: {
      type: "chevrolet dealer",
      keyword: "Santo Domingo Motors Chevrolet",
    },
  }
  onFilterSubmit = event => {
    return false
  }
  onFilterClick = event => {
    let btn = event.target
    let filterOptions = []
    if (
      btn.checked === undefined ||
      btn.checked === null ||
      btn.checked === false
    ) {
      btn.checked = true
    } else {
      btn.checked = false
    }
    let filters = document.querySelectorAll(".filtersToggle")
    for (let index = 0; index < filters.length; index++) {
      let filterBtn = filters[index]
      if (filterBtn.checked === true) {
        filterOptions.push(filterBtn.value)
        console.log("Btn: ", filterBtn.value)
      }
    }
    this.setState({ filters: { type: filterOptions } })
  }

  UNSAFE_componentWillMount() {
    if (typeof window !== "undefined") {
      window.flexform = { url: config.uff }
      !(function(f, b, e, h, n, t, s) {
        if (f.omdf) return
        n = f.omdf = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._omdf) f._omdf = n
        n.push = n
        n.queue = []
        t = b.createElement(e)
        t.async = true
        t.src = h
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        "script",
        config.uff + "/wp-content/plugins/dynamic-forms/assets/js/omdforms.js"
      )
      omdf("init", "5ca7377f3e780", "contactos")
    }
  }

  render() {
    if (this.props.pageId.id == "cGFnZTo2NTg=") {
      return (
        <div className="uk-container uk-margin-large">
          <div className="uk-width-1-1 uk-grid">
            <div className="uk-width-1-2@m uk-width-1-1@s">
              <div className="uk-width-1-1">
                <div id="contacto">
                  <ul
                    id="menu-nav"
                    className="menu-nav uk-subnav uk-subnav-pill uk-margin-remove-left"
                    uk-switcher="connect: .switcher-container"
                  >
                    <li>
                      <a href="#">Teléfonos</a>
                    </li>
                    <li>
                      <a href="#">Correos Electrónicos</a>
                    </li>
                  </ul>
                  <ul className="uk-switcher switcher-container uk-padding ">
                    <li>
                      <h3 className="uk-text-center">
                        <span data-uk-icon="icon: receiver; ratio:1.5" /> (809)
                        540 3800
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <span data-uk-icon="icon: whatsapp; ratio:1.5" /> (829)
                        521 5151
                      </h3>
                    </li>
                    <li>
                      {" "}
                      <h4 className="uk-text-center">
                        <span
                          data-uk-icon="icon:  mail
; ratio:1.5"
                        />{" "}
                        contacto@chevrolet.com.do
                      </h4>
                    </li>
                  </ul>
                </div>

                <div id="contactos" />
              </div>
            </div>
            <div className="uk-width-1-2@m uk-width-1-1@s">
              <SimpleMap
                google={this.props.google}
                Filters={this.state.filters}
              />
            </div>
          </div>
        </div>
      )
    }

    return ""
  }
}

export default Contacto
