/* eslint-disable */
import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import shadowncard from "../../assets/img/default.png"
import InputMask from "react-input-mask"

class Cotizar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      requesId: false,
      alert: false,
      error: false,
      datautos: [],
      feedBackInput: {
        apellido: "",
        cedula: "",
        celular: "",
        email: "",
        modelo: "",
        name: "",
        version_modelo: "",
      },

      urlOrginForm: this.props.urlOrginForm,
      featuredImage: shadowncard,
      title: "",
      sending: "",
      currentPrice: "",
      currentVercion: "",
      versiones: [
        {
          node: {
            precio_verion: "",
            version_modelo: "Versión del vehículo",
          },
        },
      ],
    }

    this.vehiculoChange = this.vehiculoChange.bind(this)
    this.versionChange = this.versionChange.bind(this)
    this.SendEntries = this.SendEntries.bind(this)
    this.validateInput = this.validateInput.bind(this)
    //this.inputChange = this.inputChange.bind(this)
    //this.onChange       = this.onChange.bind(this);
  }

  componentDidMount() {
    let uri = new URL(window.location)
    let slug = uri.search.split("=")
    const autos = this.state.datautos
    const dataAuto = autos.filter(row => row.node.slug === slug[1])
    if (dataAuto.length === 1) {
      this.setState({
        requesId: dataAuto[0].node.title,
        featuredImage: dataAuto[0].node.featuredImage.sourceUrl,
        title: dataAuto[0].node.title,
        currentPrice: dataAuto[0].node.versiones.edges[0].node.precio_version,
        currentVercion: dataAuto[0].node.versiones.edges[0].node.version_modelo,
        versiones: dataAuto[0].node.versiones.edges,
      })
      document.querySelector('select[name="modelo"]').value =
        dataAuto[0].node.slug
    }
  }

  vehiculoChange(e) {
    if (e !== "") {
      const dataAuto = this.state.datautos.filter(row => row.node.slug === e)
      this.setState({
        featuredImage: dataAuto[0].node.featuredImage.sourceUrl,
        title: dataAuto[0].node.title,
        currentPrice: dataAuto[0].node.versiones.edges[0].node.precio_version,
        currentVercion: dataAuto[0].node.versiones.edges[0].node.version_modelo,
        versiones: dataAuto[0].node.versiones.edges,
      })
    } else {
      this.setState({
        featuredImage: shadowncard,
        title: "",
        currentPrice: "",
        currentVercion: "",
        versiones: [
          {
            node: {
              precio_verion: "",
              version_modelo: "Versión del vehículo",
            },
          },
        ],
      })
    }
  }

  versionChange(e) {
    let { value } = e.target
    const data = this.state.versiones.filter(
      row => row.node.version_modelo === value
    )

    this.setState({
      currentPrice: data[0].node.precio_version,
      currentVercion: data[0].node.version_modelo,
    })
  }

  SendEntries() {
    if (typeof window !== "undefined") {
      const cotizarForm = Array.from(document.forms["cotizarForm"].elements)

      let data = { flexformdata: {} }

      cotizarForm.forEach(n =>
        n.type == "text" || n.type == "select-one" || n.type == "email"
          ? (data["flexformdata"][n.name] = n.value)
          : ""
      )
      this.validateInput(data["flexformdata"])
    }
  }

  validateInput(v) {
    let apellido, cedula, celular, email, modelo, name, version_modelo
    let err = false

    v["apellido"] === "" ? (apellido = "uk-form-danger") : ""
    v["cedula"] === "" ? (cedula = "uk-form-danger") : ""
    v["celular"] === "" ? (celular = "uk-form-danger") : ""
    v["email"] === "" ? (email = "uk-form-danger") : ""
    v["modelo"] == "" ? (modelo = "uk-form-danger") : ""
    v["name"] === "" ? (name = "uk-form-danger") : ""
    v["version_modelo"] == "Versión del vehículo"
      ? (version_modelo = "uk-form-danger")
      : ""

    Object.entries(v).forEach(element => {
      if (element[1] === "" || element[1] === "Versión del vehículo") {
        err = true
        this.setState({
          alert: "error",
          error: true,
          feedBackInput: {
            apellido: apellido,
            cedula: cedula,
            celular: celular,
            email: email,
            modelo: modelo,
            name: name,
            version_modelo: version_modelo,
          },
        })
      }
    })

    if (!err) {
      fetch(
        this.state.urlOrginForm +
          `/wp-json/OmdFlex_forms/v1/save_form/5ca88fc33488f`,
        {
          method: "POST",
          body: JSON.stringify(v),
        }
      )
        .then(response => {
          this.setState({
            alert: response["ok"],
            error: false,
            feedBackInput: {
              apellido: "",
              cedula: "",
              celular: "",
              email: "",
              modelo: "",
              name: "",
              version_modelo: "",
            },
          })
        })
        .catch(fail => {
          console.log(fail)
        })
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            chevrolet {
              chevrolets(first: 1000, where: { status: PUBLISH }) {
                edges {
                  node {
                    id
                    slug
                    title
                    content
                    featuredImage {
                      id
                      sourceUrl
                    }
                    versiones {
                      edges {
                        node {
                          precio_version
                          sku_version
                          version_modelo
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          this.state.datautos = data.chevrolet.chevrolets.edges
          const dataAutos = data.chevrolet.chevrolets.edges

          return (
            <div className="uk-container uk-container-expand uk-margin-large">
              <form
                id="cotizarForm"
                className="uk-form-stacked uk-margin-medium"
              >
                <div
                  className="uk-flex uk-grid uk-child-width-1-3@m uk-child-width-1-1@s"
                  data-uk-grid="true"
                >
                  <div>
                    <h4>1. Tu Vehículo</h4>

                    <div className="uk-margin">
                      <img src={this.state.featuredImage} />
                      <h4 className="uk-margin-remove-botom">
                        {this.state.title}{" "}
                        <span className="uk-text-color-yellow">
                          {this.state.currentVercion}
                        </span>
                      </h4>
                      <h5 className="uk-text-lead uk-margin-remove-top uk-padding-remove">
                        {this.state.currentPrice != "" &&
                          this.state.currentPrice > 0 &&
                          `$${new Intl.NumberFormat("en-IN").format(
                            this.state.currentPrice
                          )}`}
                      </h5>
                    </div>
                    <div className="uk-margin">
                      <div className="uk-form-controls">
                        <div className="uk-inline-c">
                          <span
                            className="uk-form-icon uk-form-icon-flip fas fa-caret-down"

                          />
                          <select
                            className={`uk-select ${
                              this.state.feedBackInput.modelo
                            }`}
                            id="form-modelo-select"
                            name="modelo"
                            onChange={e => this.vehiculoChange(e.target.value)}
                          >
                            <option value="">SELECCIONA MODELO*</option>
                            {dataAutos.map(item => (
                              <option key={item.node.id} value={item.node.slug}>
                                {item.node.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="uk-margin">
                      <div className="uk-form-controls">
                        <div className="uk-inline-c">
                          <span
                            className="uk-form-icon uk-form-icon-flip fas fa-caret-down"

                          />

                          <select
                            className={`uk-select ${
                              this.state.feedBackInput.version_modelo
                            }`}
                            id="form-version_modelo"
                            name="version_modelo"
                            onChange={this.versionChange}
                          >
                            {this.state.versiones.length > 0 &&
                              this.state.versiones.map((items, key) => (
                                <option
                                  key={key}
                                  value={items.node.version_modelo}
                                >
                                  {items.node.version_modelo}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4>2. Información Personal</h4>
                    <div>
                      <div className="">
                        <label
                          className="uk-form-label"
                          htmlFor="form-name-text"
                        >
                          NOMBRE*
                        </label>
                        <div className="uk-form-controls">
                          <input
                            name="name"
                            className={`uk-input ${
                              this.state.feedBackInput.name
                            }`}
                            id="form-name-text"
                            type="text"
                            placeholder="Juan PXXXXXXX"
                            onChange={e => this.inputChange(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="uk-margin-medium">
                        <label
                          className="uk-form-label"
                          htmlFor="form-cedula-text"
                        >
                          CÉDULA*
                        </label>
                        <div className="uk-form-controls">
                          <InputMask
                            name="cedula"
                            mask="999-9999999-9"
                            className={`uk-input ${
                              this.state.feedBackInput.cedula
                            }`}
                            id="form-cedula-text"
                            type="text"
                            placeholder="402-XXXXXXX-X"
                          />
                        </div>
                      </div>
                      <div className="uk-margin-medium">
                        <label
                          className="uk-form-label"
                          htmlFor="form-email-text"
                        >
                          EMAIL*
                        </label>
                        <div className="uk-form-controls">
                          <input
                            name="email"
                            className={`uk-input ${
                              this.state.feedBackInput.email
                            }`}
                            id="form-email-text"
                            type="email"
                            placeholder="correo@emXXX.com"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="uk-margin-small-top">
                    <div className="uk-margin-medium-top">
                      <label
                        className="uk-form-label"
                        htmlFor="form-apellido-select"
                      >
                        APELLIDO*
                      </label>
                      <div className="uk-form-controls">
                        <input
                          name="apellido"
                          className={`uk-input ${
                            this.state.feedBackInput.apellido
                          }`}
                          id="form-apellido-text"
                          type="text"
                          placeholder="Mella RamXXX"
                        />
                      </div>
                    </div>

                    <div className="uk-margin-medium">
                      <label
                        className="uk-form-label"
                        htmlFor="form-celular-text"
                      >
                        CELULAR*
                      </label>
                      <div className="uk-form-controls">
                        <InputMask
                          name="celular"
                          mask="999-999-9999"
                          className={`uk-input ${
                            this.state.feedBackInput.celular
                          }`}
                          id="form-celular-text"
                          type="text"
                          placeholder="840-5XX-XXXX"
                        />
                      </div>
                    </div>
                    <div className="uk-margin">
                      <small>Los campos marcados * son obligatorios.</small>
                      {this.state.alert == true && (
                        <div
                          className="uk-alert-success uk-padding-small"
                          uk-alert="true"
                        >
                          <a className="uk-alert-close" uk-close="true" />
                          <p>Tu solicitud ha sido enviada</p>
                        </div>
                      )}

                      {this.state.alert == "error" && (
                        <div
                          className="uk-alert-danger uk-padding-small"
                          uk-alert="true"
                        >
                          <a className="uk-alert-close" uk-close="true" />
                          <p>
                            Todos los campos marcados con (*) son obligatorios.
                          </p>
                        </div>
                      )}
                      <div className="uk-form-controls">
                        <input
                          className="uk-button uk-button-default uk-width btn-sumition uk-padding-remove"
                          id="form-stacked-text"
                          type="button"
                          onClick={this.SendEntries}
                          value="ENVIAR"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h6>
                  *La información presentada y valores son de carácter
                  informativo a modo de referencia sin incluir el valor del
                  seguro. Solicite una cotización en Santo Domingo Motors y
                  dealers autorizados para mayor exactitud, detalle de las
                  configuraciones de los modelos disponibles.
                </h6>
              </form>
            </div>
          )
        }}
      />
    )
  }
}

export default Cotizar
