import React, { Component } from "react"
import { Map, GoogleApiWrapper } from "google-maps-react"

export class MapContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { center: [], zoom: 12, markers: [], filters: [] }
  }
  //Marcador con millas a la redonda de tu ubicacion actual
  mapRender() {
    let parent = this
    navigator.geolocation.getCurrentPosition(function(position) {
      parent.setState(
        {
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          zoom: 14,
        },
        function() {
          const { center, zoom, filters } = parent.state
          const GoogleApi = parent.props.google
          var map = new GoogleApi.maps.Map(
              document.getElementById("map").children[0],
              {
                zoom,
                center,
                styles: [
                  {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [
                      {
                        color: "#e9e9e9",
                      },
                      {
                        lightness: 17,
                      },
                    ],
                  },
                  {
                    featureType: "landscape",
                    elementType: "geometry",
                    stylers: [
                      {
                        color: "#f5f5f5",
                      },
                      {
                        lightness: 20,
                      },
                    ],
                  },
                  {
                    featureType: "road.highway",
                    elementType: "geometry.fill",
                    stylers: [
                      {
                        color: "#ffffff",
                      },
                      {
                        lightness: 17,
                      },
                    ],
                  },
                  {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [
                      {
                        color: "#ffffff",
                      },
                      {
                        lightness: 29,
                      },
                      {
                        weight: 0.2,
                      },
                    ],
                  },
                  {
                    featureType: "road.arterial",
                    elementType: "geometry",
                    stylers: [
                      {
                        color: "#ffffff",
                      },
                      {
                        lightness: 18,
                      },
                    ],
                  },
                  {
                    featureType: "road.local",
                    elementType: "geometry",
                    stylers: [
                      {
                        color: "#ffffff",
                      },
                      {
                        lightness: 16,
                      },
                    ],
                  },
                  {
                    featureType: "poi",
                    elementType: "geometry",
                    stylers: [
                      {
                        color: "#f5f5f5",
                      },
                      {
                        lightness: 21,
                      },
                    ],
                  },
                  {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [
                      {
                        color: "#dedede",
                      },
                      {
                        lightness: 21,
                      },
                    ],
                  },
                  {
                    elementType: "labels.text.stroke",
                    stylers: [
                      {
                        visibility: "on",
                      },
                      {
                        color: "#ffffff",
                      },
                      {
                        lightness: 16,
                      },
                    ],
                  },
                  {
                    elementType: "labels.text.fill",
                    stylers: [
                      {
                        saturation: 36,
                      },
                      {
                        color: "#333333",
                      },
                      {
                        lightness: 40,
                      },
                    ],
                  },
                  {
                    elementType: "labels.icon",
                    stylers: [
                      {
                        visibility: "off",
                      },
                    ],
                  },
                  {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [
                      {
                        color: "#f2f2f2",
                      },
                      {
                        lightness: 19,
                      },
                    ],
                  },
                  {
                    featureType: "administrative",
                    elementType: "geometry.fill",
                    stylers: [
                      {
                        color: "#fefefe",
                      },
                      {
                        lightness: 20,
                      },
                    ],
                  },
                  {
                    featureType: "administrative",
                    elementType: "geometry.stroke",
                    stylers: [
                      {
                        color: "#fefefe",
                      },
                      {
                        lightness: 17,
                      },
                      {
                        weight: 1.2,
                      },
                    ],
                  },
                ],
              }
            ),
            UserRequest = {
              location: center,
              radius: "2000",
              type: filters.type,
              keyword: filters.keyword,
            },
            Office = {
              placeId: "",
            }
          /*            CircleOptions = {
              strokeColor: "#00A44F",
              strokeOpacity: 0.1,
              strokeWeight: 2,
              fillColor: "#00A44F",
              fillOpacity: 0.075,
              map: map,
              center: center,
              radius: 3000
            };*/
          let branchOffices = []
          //let infowindow = new GoogleApi.maps.InfoWindow();
          //let Circle = new GoogleApi.maps.Circle(CircleOptions)
          //Service for the search
          let service = new GoogleApi.maps.places.PlacesService(map)
          service.nearbySearch(UserRequest, PlacesMarker)
          //
          function PlacesMarker(results, status) {
            if (status === GoogleApi.maps.places.PlacesServiceStatus.OK) {
              for (var i = 0; i < results.length; i++) {
                var place = results[i]
                var Generalmarker

                Office["placeId"] = place["place_id"]

                branchOffices.push([
                  place.name,
                  place.geometry.location,
                  place.website,
                  place.rating,
                ])

                Generalmarker = new GoogleApi.maps.Marker({
                  position: place["geometry"]["location"],
                  clickable: true,
                  map,
                  title: place["name"],
                })

                let infowindow = new GoogleApi.maps.InfoWindow()
                infowindow.setContent(place.name)
                GoogleApi.maps.event.addListener(
                  Generalmarker,
                  "click",
                  function() {
                    //infowindow.setContent(place.name);

                    infowindow.open(map, this)
                    // map.setCenter(place.geometry.location);
                  }
                )
              }
            }
          }
        }
      ) // fin
    })
  }
  componentDidMount() {
    this.mapRender()
  }

  componentDidUpdate() {
    const { Filters } = this.props
    if (Filters !== this.state.filters) {
      this.setState({ filters: Filters })
      this.mapRender()
      //console.log("Estado", this.state);
    }
  }
  render() {
    return (
      <div id="map">
        <Map
          google={this.props.google}
          initialCenter={this.props.center}
          zoom={14}
        />
      </div>
    )
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyBomri_2uPruzi7Fbu7tDGR_mhu_rDHbI0",
})(MapContainer)

/*

 https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=-33.8670522,151.1957362&radius=1500&type=restaurant&keyword=cruise&key=AIzaSyBomri_2uPruzi7Fbu7tDGR_mhu_rDHbI0


 */
