import React from 'react'
import { StaticQuery, graphql,Link } from "gatsby"

class Blogs extends React.Component{

  render(){
    return (
      <StaticQuery
        query={graphql`
          query{
            chevrolet {
             posts(last:100,where: {status:PUBLISH}){
                edges {
                  node {
                    id
                    slug
                    title
                    excerpt
                    featuredImage {
                      id
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {



          return (

              <div id="post-area" className="uk-container uk-container-expand uk-padding-large">

                    <h2>Noticias</h2>

                    <div
                      className="uk-child-width-1-2@s uk-child-width-1-3@m"
                      data-uk-grid="true"
                    >
                        {data.chevrolet.posts.edges.map(row => (
                        <div key={row.node.id} className="uk-grid-match">
                          <div className="uk-card post-item  uk-paddin-remove ">
                            <Link to={'/'+row.node.slug}>
                              <div
                                className=" uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-margin-bottom"
                                data-src={row.node.featuredImage.sourceUrl}
                                data-uk-img="true"
                              />
                              <p className="item-title">{row.node.title}</p>
                            </Link>
                            <hr />
                            <div className="content">
                              <div dangerouslySetInnerHTML={{ __html:  row.node.excerpt}} />
                              <Link to={row.node.slug}> Leer más </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
              </div>
          )
        }}
      />
    )
  }

}

export default Blogs


/* export const query = graphql`
  query{
    chevrolet {
      posts (where: {status:PUBLISH}){
        edges {
          node {
            id
            slug
            title
            excerpt
            featuredImage {
              id
              sourceUrl
            }
          }
        }
      }
    }
  }
` */