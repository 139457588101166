import React from "react";
import { useForm } from "react-hook-form";

function Form({
  modelo,
  dataAutos,
  anio,
  chasis,
  titulo,
  name,
  telefono,
  celular,
  apellido,
  email,
  alert,
  alertType,
  SendEntries
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => SendEntries(data);
  const isNumeric = (x) => { return !/\D/.test(x) }


  return (
    <form
      method="post"
      id="cotizarForm"
      className="uk-form-stacked uk-margin-medium"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <div>
          <div
            className="uk-flex
                                uk-grid uk-child-width-1-2@m uk-child-width-1-1@s uk-margin uk-grid-collapse"
            data-uk-grid="true"
          >
            <div className="uk-padding-small">
              <div className="uk-form-controls">
                <label className="uk-form-label" htmlFor="form-name-text">
                  Modelo*
                </label>
                <div className="uk-inline-c">
                  <select
                  {...register("modelo", { required: true })}
                    className={`uk-select ${modelo}`}
                    id="form-modelo-select"
                    name="modelo"
                    // onChange={e => this.vehiculoChange(e.target.value)}
                  >
                    <option value="">Selecciona el Modelo*</option>
                    {dataAutos.map((item) => (
                      <option key={item.node.id} value={item.node.slug}>
                        {item.node.title}
                      </option>
                    ))}
                  </select>
                  {errors.modelo && (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      margin: "3px 0px -22px 15px",
                    }}
                  >
                    El Modelo es requerido.
                  </p>
                )}
                </div>
              </div>
            </div>

            <div className="uk-padding-small">
              <label className="uk-form-label" htmlFor="form-anio-text">
                Año*
              </label>
              <div className="uk-form-controls">
                <input
                  {...register("anio", { required: true, validate: isNumeric })}
                  name="anio"
                  className={`uk-input ${anio}`}
                  id="form-anio-text"
                  type="text"
                  placeholder="2005"
                  maxLength='4'
                  //onChange={e => this.inputChange(e.target.value)}
                />
               {errors.anio && (errors.anio.type === "required") ? (<p style={{ fontSize: '12px', color: 'red', margin: "3px 0px -22px 15px" }}>El año es requerido.</p>) : ''}
               {errors.anio && (errors.anio.type === "validate") ? (<p style={{ fontSize: '12px', color: 'red', margin: "3px 0px -22px 15px" }}>Este campo solo admite números (sin guiones).</p>) : ''}
              </div>
            </div>

            <div className="uk-width-1-1 uk-padding-small">
              <label className="uk-form-label" htmlFor="form-anio-text">
                VIN/Chasis*
              </label>
              <div className="uk-form-controls ">
                <input
                {...register("chasis", { required: true })}
                  name="chasis"
                  className={`uk-input ${chasis}`}
                  id="form-chasis-text"
                  type="text"
                  placeholder=""
                  //onChange={e => this.inputChange(e.target.value)}
                />
                {errors.chasis && (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        margin: "3px 0px -22px 15px",
                      }}
                    >
                      El VN/Chasis es requerido.
                    </p>
                  )}
              </div>
            </div>
            {/* <div >

                        <div className="uk-form-controls">
                          <div className="uk-inline-c">
                            <select
                              className={`uk-select ${
                                this.state.feedBackInput.version_modelo
                              }`}
                              id="form-version_modelo"
                              name="version_modelo"
                              onChange={this.versionChange}
                              value={this.state.currentVercion.replace('_',' ')}
                            >
                            
                                {this.state.versiones.length > 0 &&
                                this.state.versiones.map((items, key) => (                            
                                  
                                  <option
                                    key={key}
                                    value={items.node.version_modelo }
                                  >
                                    {items.node.version_modelo}
                                   
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div> */}

            <div className="uk-padding-small">
              <div className="uk-form-controls">
                <label className="uk-form-label" htmlFor="form-titulo-select">
                  Título*
                </label>
                <div className="uk-inline-c">
                  <select
                   {...register("titulo", { required: true })}
                    className={`uk-select ${titulo}`}
                    id="form-titulo-select"
                    name="titulo"
                  >
                    <option value="">Selecciona uno de la lista</option>
                    <option>Sr.</option>
                    <option>Sra.</option>
                    {/* <option>Srs</option> */}
                  </select>
                  {errors.titulo && (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      margin: "3px 0px -22px 15px",
                    }}
                  >
                    El Título es requerido.
                  </p>
                )}
                </div>
              </div>
            </div>

            <div className="uk-padding-small">
              <label className="uk-form-label" htmlFor="form-name-text">
                Nombre*
              </label>
              <div className="uk-form-controls">
                <input
                {...register("name", { required: true })}
                  name="name"
                  className={`uk-input ${name}`}
                  id="form-name-text"
                  type="text"
                  placeholder="Juan PXXXXXXX"
                  //onChange={e => this.inputChange(e.target.value)}
                />
                {errors.name && (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      margin: "3px 0px -22px 15px",
                    }}
                  >
                    El Nombre es requerido.
                  </p>
                )}
              </div>
            </div>

            <div className="uk-padding-small">
              <label className="uk-form-label" htmlFor="form-apellido-text">
                Apellidos*
              </label>
              <div className="uk-form-controls">
                <input
                {...register("apellido", { required: true })}
                  name="apellido"
                  className={`uk-input ${apellido}`}
                  id="form-apellido-text"
                  type="text"
                  placeholder="Mella RamXXX"
                  //onChange={e => this.inputChange(e.target.value)}
                />
                {errors.apellido && (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      margin: "3px 0px -22px 15px",
                    }}
                  >
                    Los/el apellido(s) es requerido.
                  </p>
                )}
              </div>
            </div>

            <div className="uk-padding-small">
              <label className="uk-form-label" htmlFor="form-email-text">
                Email*
              </label>
              <div className="uk-form-controls">
                <input
                {...register("email", { required: true })}
                  name="email"
                  className={`uk-input ${email}`}
                  id="form-email-text"
                  type="email"
                  placeholder="correo@emXXX.com"
                  //onChange={e => this.inputChange(e.target.value)}
                />
                {errors.email && (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      margin: "3px 0px -22px 15px",
                    }}
                  >
                    El Email es requerido.
                  </p>
                )}
              </div>
            </div>

            <div className="uk-padding-small">
              <label className="uk-form-label" htmlFor="form-telefono-text">
                Teléfono*
              </label>
              <div className="uk-form-controls">
                <input
                {...register("telefono", { required: true, validate: isNumeric })}
                  name="telefono"
                  className={`uk-input ${telefono}`}
                  id="form-telefono-text"
                  type="tel"
                  placeholder="840-5XX-XXXX"
                  maxLength='10'
                  //onChange={e => this.inputChange(e.target.value)}
                />
                {errors.telefono && (errors.telefono.type === "required") ? (<p style={{ fontSize: '12px', color: 'red', margin: "3px 0px -22px 15px" }}>El teléfono es requerido.</p>) : ''}
                {errors.telefono && (errors.telefono.type === "validate") ? (<p style={{ fontSize: '12px', color: 'red', margin: "3px 0px -22px 15px" }}>Este campo solo admite números (sin guiones).</p>) : ''}


              </div>
            </div>

            <div className="uk-padding-small">
              <label className="uk-form-label" htmlFor="form-celular-text">
                Celular*
              </label>
              <div className="uk-form-controls">
                <input
                {...register("celular", { required: true, validate: isNumeric })}
                  name="celular"
                  className={`uk-input ${celular}`}
                  id="form-celular-text"
                  type="text"
                  placeholder="840-5XX-XXXX"
                  maxLength='10'
                  //onChange={e => this.inputChange(e.target.value)}
                />
                {errors.celular && (errors.celular.type === "required") ? (<p style={{ fontSize: '12px', color: 'red', margin: "3px 0px -22px 15px" }}>El celular es requerido.</p>) : ''}
                {errors.celular && (errors.celular.type === "validate") ? (<p style={{ fontSize: '12px', color: 'red', margin: "3px 0px -22px 15px" }}>Este campo solo admite números (sin guiones).</p>) : ''}
              </div>
            </div>

            <div className="uk-padding-small">
              <div className="uk-form-controls">
                <label className="uk-form-label" htmlFor="form-contacto-select">
                  Método de contacto preferido
                </label>
                <div className="uk-inline-c">
                  <select
                  {...register("contacto")}
                    className={`uk-select ${titulo}`}
                    id="form-contacto-select"
                    name="contacto"
                  >
                    <option value="">Selecciona de la lista</option>
                    <option value="celular">Celular</option>
                    <option value="Teléfono Fijo">Teléfono Fijo</option>
                    <option value="Email">Email</option>
                  </select>
                 
                </div>
              </div>
            </div>

            <div className="uk-margin uk-padding-small">
              <small>Los campos marcados * son obligatorios.</small>
              {alert === true && (
                <div
                  className="uk-alert-success uk-padding-small"
                  uk-alert="true"
                >
                  <a className="uk-alert-close" uk-close="true" />
                  <p>Tu solicitud ha sido enviada</p>
                </div>
              )}
              {alert === "error" && (
                <div
                  className="uk-alert-danger uk-padding-small"
                  uk-alert="true"
                >
                  <a className="uk-alert-close" uk-close="true" />
                  <p>Todos los campos marcados con (*) son obligatorios.</p>
                </div>
              )}
              <div className={alertType} data-uk-alert>
                <a className="uk-alert-close" uk-close />
                <p>{alert}</p>
              </div>
              <div className="uk-form-controls">
                {/* <input
                            className="btn btn--primary uk-width-1-2@m"

                            type="button"
                            onClick={this.SendEntries}
                            value={this.state.btnText}
                          /> */}
                <button className="uk-button uk-button-default uk-width btn-sumition uk-padding-remove">
                  ENVIAR
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div  className="uk-flex uk-grid uk-child-width-1-2@m uk-child-width-1-1@s" data-uk-grid>


                    <div>
                      <h4>Información Personal</h4>
                      <div>
                       <div >

                        <div className="uk-form-controls">
                        <label
                            className="uk-form-label"
                            htmlFor="form-titulo-text"
                          >
                           Titulo*
                          </label>
                          <div className="uk-inline-c">
                            <select
                              className={`uk-select ${
                                this.state.feedBackInput.version_modelo
                              }`}
                              id="form-titulo-text"
                              name="form-titulo-text"
                              // onChange={this.versionChange}
                            >
                                <option>Seleccione</option>
                                <option>Señor</option>
                                <option>Señora</option>
                                <option>Señores</option>
                            </select>
                          </div>
                        </div>
                      </div>
                        <div className="">
                          <label
                            className="uk-form-label"
                            htmlFor="form-name-text"
                          >
                            Nombre*
                          </label>
                          <div className="uk-form-controls">
                            <input
                              name="name"
                              className={`uk-input ${
                                this.state.feedBackInput.name
                              }`}
                              id="form-name-text"
                              type="text"
                              placeholder="Juan PXXXXXXX"
                              onChange={e => this.inputChange(e.target.value)}
                            />
                          </div>
                        </div>
                          <div className="uk-margin-medium">

                            <label
                              className="uk-form-label"
                              htmlFor="form-apellido-select"
                            >
                              Apellidos*
                          </label>
                            <div className="uk-form-controls">
                              <input
                                name="apellido"
                                className={`uk-input ${this.state.feedBackInput.apellido
                                  }`}
                                id="form-apellido-text"
                                type="text"
                                placeholder="Mella RamXXX"
                              />
                            </div>
                          </div>
                        <div className="uk-margin-medium">
                          <label
                            className="uk-form-label"
                            htmlFor="form-cedula-text"
                          >
                            Teléfono*
                          </label>
                          <div className="uk-form-controls">
                            <InputMask
                              name="cedula"
                              mask="999-9999999-9"
                              className={`uk-input ${
                                this.state.feedBackInput.cedula
                              }`}
                              id="form-cedula-text"
                              type="text"
                              placeholder="402-XXXXXXX-X"
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="uk-margin-small-top">
                      <div className="uk-margin-medium-top">

                          <label
                            className="uk-form-label"
                            htmlFor="form-email-text"
                          >
                            Email*
                          </label>
                          <div className="uk-form-controls">
                            <input
                              name="email"
                              className={`uk-input ${this.state.feedBackInput.email
                                }`}
                              id="form-email-text"
                              type="email"
                              placeholder="correo@emXXX.com"
                            />
                          </div>
                      </div>
                      <div className="uk-margin-medium">
                        <label
                          className="uk-form-label"
                          htmlFor="form-celular-text"
                        >
                          Celular*
                        </label>
                        <div className="uk-form-controls">
                          <InputMask
                            name="celular"
                            mask="999-999-9999"
                            className={`uk-input ${
                              this.state.feedBackInput.celular
                            }`}
                            id="form-celular-text"
                            type="text"
                            placeholder="840-5XX-XXXX"
                          />
                        </div>
                      </div>
                      <div className="uk-margin">
                        <small>Los campos marcados * son obligatorios.</small>
                        {alert === true && (
                          <div
                            className="uk-alert-success uk-padding-small"
                            uk-alert="true"
                          >
                            <a className="uk-alert-close" uk-close="true" />
                            <p>Tu solicitud ha sido enviada</p>
                          </div>
                        )}
                        {alert === "error" && (
                          <div
                            className="uk-alert-danger uk-padding-small"
                            uk-alert="true"
                          >
                            <a className="uk-alert-close" uk-close="true" />
                            <p>
                              Todos los campos marcados con (*) son obligatorios.
                            </p>
                          </div>
                        )}
                          <div className={alertType} data-uk-alert>
                            <a className="uk-alert-close" uk-close></a>
                            <p>{alert}</p>
                          </div>
                        <div className="uk-form-controls">
                          <input
                            className="btn btn--primary uk-width-1-2@m"

                            type="button"
                            onClick={this.SendEntries}
                            value={this.state.btnText}
                          />
                        </div>
                      </div>
                    </div>
                    </div> */}
      </div>

      {/* <hr />
                  <h6>
                  *Campo obligatorio.
                  </h6> */}
    </form>
  );
}
export default Form;
