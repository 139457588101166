import React from "react"
import { graphql, StaticQuery } from "gatsby"
import shadowncard from "../../assets/img/default.png"
//import ItemList from './itemList'

//const { info } = require('../../utils/calculatorInfo.json')

class Vehiculo extends React.Component {
  /**
   * [constructor description]
   * @param  {[type]} props [description]
   * @return {[type]}       [description]
   */
  constructor(props) {
    super(props)
    this.state = {
      modelos: this.props.chevrolets.edges,
      bancos: this.props.bancos.edges,
      totalUs: 0,
      totalRd: 0,
      cuotas: 0,
      tasa: 0,
      plazo: 0,
      valor_vehiculo: 0,
      monto_prociento: 0,
      monto_inicial: 0,
      value: 7,
      range: {
        value: 6,
        limits: {
          min: 0,
          max: 0,
        },
      },
      disabled: true,
      autoImage: shadowncard,
      version_modelo: [
        {
          node: {
            precio_verion: "",
            version_modelo: "Versión del vehículo",
          },
        },
      ],
      interesList: [
        {
          node: {
            condicion_tasa: "SELECCIONAR TASA*",
            tasa_financiamiento: "",
            plazos_tasa: [],
          },
        },
      ],
    }

    this.handleChange = this.handleChange.bind(this)
    this.versionChange = this.versionChange.bind(this)
    this.bancoChange = this.bancoChange.bind(this)
    this.tasaChange = this.tasaChange.bind(this)
    this.plazoChange = this.plazoChange.bind(this)
    this.calcularioCuotas = this.calcularioCuotas.bind(this)
  }

  /**
   * [handleChange description]
   * @param  {[type]} e   [description]
   * @param  {[type]} arr [description]
   * @return {[type]}     [description]
   */
  handleChange(e) {
    const listVersiones = this.state.modelos.filter(row => row.node.slug === e)
    if (listVersiones[0] !== undefined) {
      this.setState({
        version_modelo: listVersiones[0].node.versiones.edges,
        autoImage: listVersiones[0].node.featuredImage.sourceUrl,
        disabled: false,
        totalUs: parseInt(
          listVersiones[0].node.versiones.edges[0].node.precio_version
        ),
        totalRd:
          parseInt(
            listVersiones[0].node.versiones.edges[0].node.precio_version
          ) * 58.10,
        monto_inicial:
          parseInt(
            listVersiones[0].node.versiones.edges[0].node.precio_version
          ) *
          58.10 *
          (parseInt(this.state.range.limits.min) / 100),
        valor_vehiculo: parseInt(
          listVersiones[0].node.versiones.edges[0].node.precio_version
        ),
      })
    } else {
      this.defaultValues()
    }
  }

  /**
   * [tasaChange description]
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  tasaChange(e) {
    this.setState({ tasa: parseInt(e) })
    this.calcularioCuotas()
  }

  /**
   * [plazoChange description]
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  plazoChange(e) {
    const label = Array.from(document.querySelectorAll("#content-plazo label"))
    label.map(item => item.classList.remove("uk-active"))

    const { value } = e.target
    const n = value.match(/\d+/g).map(Number)

    e.target.parentNode.classList.add("uk-active")

    this.setState({ plazo: n[0] })
    this.calcularioCuotas()
  }

  /**
   * [bancoChange description]
   * @param  {[type]} e   [description]
   * @param  {[type]} arr [description]
   * @return {[type]}     [description]
   */
  bancoChange(e) {
    const listTasa = this.state.bancos.filter(row => row.node.id === e)

    if (document.querySelector("#content-plazo label input") != null) {
      const label = Array.from(
        document.querySelectorAll("#content-plazo label")
      )
      label.map(item => item.classList.remove("uk-active"))
    }

    if (listTasa[0] !== undefined) {
      let plazo_tasa = listTasa[0].node.financieros.edges[0].node.plazos_tasa[0]
        .match(/\d+/g)
        .map(Number)

      // if(listTasa )
      this.setState({
        interesList: listTasa[0].node.financieros.edges,
        tasa: parseInt(
          listTasa[0].node.financieros.edges[0].node.tasa_financiamiento
        ),
        plazo: plazo_tasa[0],
        value: 0,
        monto_inicial:
          this.state.valor_vehiculo *
          58.10 *
          (parseInt(listTasa[0].node.inicial[0]) / 100),
        monto_prociento: parseInt(listTasa[0].node.inicial[0]) / 100,
        range: {
          value: 0,
          limits: {
            min: listTasa[0].node.inicial[0],
            max: listTasa[0].node.inicial[1],
          },
        },
      })

      this.calcularioCuotas()
    } else {
      this.defaultValues()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { plazo } = this.state
    let self = this

    if (plazo !== prevState.plazo) {
      self.calcularioCuotas()
    }

    if (document.querySelector("#content-plazo label input[checked]") != null) {
      document
        .querySelector("#content-plazo label input[checked]")
        .parentNode.classList.add("uk-active")
    }
  }

  defaultValues() {
    this.setState({
      modelos: this.props.chevrolets.edges,
      bancos: this.props.bancos.edges,
      totalUs: 0,
      totalRd: 0,
      cuotas: 0,
      tasa: 0,
      plazo: 0,
      valor_vehiculo: 0,
      monto_prociento: 0,
      monto_inicial: 0,
      value: 7,
      range: {
        value: 6,
        limits: {
          min: 0,
          max: 0,
        },
      },
      disabled: true,
      autoImage: shadowncard,
      version_modelo: [
        {
          node: {
            precio_verion: "",
            version_modelo: "Versión del vehículo",
          },
        },
      ],
      interesList: [
        {
          node: {
            condicion_tasa: "SELECCIONAR TASA*",
            tasa_financiamiento: "",
            plazos_tasa: [],
          },
        },
      ],
    })
    this.calcularioCuotas()
  }
  /**
   * [versionChange description]
   * @param  {[type]} total [description]
   * @return {[type]}       [description]
   */
  versionChange(total) {
    this.setState({
      total,
      totalUs: parseInt(total),
      totalRd: parseInt(total) * 58.10,
      valor_vehiculo: parseInt(total),
      monto_inicial:
        total * 58.10 * (parseInt(this.state.range.limits.min) / 100),
    })
    this.calcularioCuotas()
  }
  /**
   * [componentDidMount description]
   * @return {[type]} [description]
   */
  componentDidMount() {
    let uri = new URL(window.location)
    let slug = uri.search.split("=")

    const listVersiones = this.state.modelos.filter(
      row => row.node.slug === slug[1]
    )

    if (listVersiones.length === 1) {
      this.setState({
        version_modelo: listVersiones[0].node.versiones.edges,
        autoImage: listVersiones[0].node.featuredImage.sourceUrl,
        disabled: false,
        totalUs: parseInt(
          listVersiones[0].node.versiones.edges[0].node.precio_version
        ),
        totalRd:
          parseInt(
            listVersiones[0].node.versiones.edges[0].node.precio_version
          ) * 58.10,
        monto_inicial:
          parseInt(
            listVersiones[0].node.versiones.edges[0].node.precio_version
          ) *
          58.10 *
          (parseInt(this.state.range.limits.min) / 100),
        valor_vehiculo: parseInt(
          listVersiones[0].node.versiones.edges[0].node.precio_version
        ),
      })
      document.querySelector('select[name="modelo"]').value =
        listVersiones[0].node.slug
    }
    //this.calcularioCuotas()
  }

  calcularioCuotas() {
    let cuotas =
      ((this.state.tasa / 1200) *
        ((this.state.valor_vehiculo * 58.10 -
          this.state.valor_vehiculo * 58.10 * this.state.monto_prociento) *
          -1) *
        Math.pow(1 + this.state.tasa / 1200, this.state.plazo * 12)) /
      (1 - Math.pow(1 + this.state.tasa / 1200, this.state.plazo * 12))

    if (!isNaN(cuotas)) {
      this.setState({ cuotas: cuotas })
    } else {
      this.setState({ cuotas: 0 })
    }
  }

  render() {
    return (
      <section className="uk-section">
        <form>
          <div className="uk-container uk-container-expand">
            <div className="uk-flex uk-flex-middle">
              <h2 className="uk-h3 uk-margin-remove uk-flex-1 uk-text-bold uk-text-center uk-text-uppercase bsc-deep-blue">
                CALCULADORA DE CUOTAS
              </h2>
            </div>

            <div className="uk-container uk-container-expand">
              <div
                className="uk-grid uk-child-width-1-1@s uk-child-width-1-3@m "
                data-uk-grid="true"
              >
                <div>
                  <div>
                    <h4 className="uk-h4 uk-text-bold uk-text-capitalize uk-text-left uk-margin-remove-top uk-margin-remove-bottom uk-margin-left bsc-bright-blue">
                      Cotizar
                    </h4>
                    <img
                      src={this.state.autoImage}
                      alt={this.state.autoTitle}
                      className=" uk-width-expand uk-margin-bottom"
                    />
                  </div>
                  <div className="uk-flex uk-flex-around">
                    <div>
                      <h4 className="uk-h4 uk-text-bold uk-margin-remove uk-flex-1 uk-text-left bsc-bright-blue">
                        Valor del Vehículo
                      </h4>
                      <h2 className="uk-h2 uk-text-bold uk-margin-remove uk-flex-1 uk-text-left bsc-bright-blue">
                        US$
                        {new Intl.NumberFormat().format(this.state.totalUs)}
                      </h2>
                      <h3 className="uk-h3 uk-text-bold uk-margin-remove uk-flex-1 uk-text-left bsc-bright-blue uk-text-color-yellow">
                        RD$
                        {new Intl.NumberFormat().format(
                          parseInt(this.state.totalRd)
                        )}
                      </h3>
                    </div>
                    <div>
                      <h4 className="uk-h4 uk-text-bold uk-margin-remove uk-flex-1 uk-text-right bsc-bright-blue uk-text-color-yellow">
                        Inicial RD$
                        {new Intl.NumberFormat().format(
                          parseInt(this.state.monto_inicial)
                        )}
                      </h4>

                      <h4 className="uk-h4 uk-text-bold uk-margin-remove uk-flex-1 uk-text-right bsc-bright-blue ">
                        Coutas Mensual
                      </h4>
                      <h2 className="uk-h2 uk-text-bold uk-margin-remove uk-flex-1 uk-text-right bsc-bright-blue">
                        RD${new Intl.NumberFormat().format(this.state.cuotas)}
                      </h2>
                    </div>
                  </div>
                  <hr />
                  <h6>
                    *La información presentada y valores son de carácter
                    informativo a modo de referencia sin incluir el valor del
                    seguro. Solicite una cotización en Santo Domingo Motors y
                    dealers autorizados para mayor exactitud, detalle de las
                    configuraciones de los modelos disponibles.
                  </h6>
                </div>
                {/* end item group*/}
                <div>
                  <div>
                    <label
                      className="uk-form-label uk-text-bold uk-text-left bsc-bright-blue"
                      htmlFor="form-modelo-select"
                    >
                      Modelo de vehículo
                    </label>
                    <div className="uk-form-controls">
                      <div className="uk-inline-c">
                        <span
                            className="uk-form-icon uk-form-icon-flip fas fa-caret-down"

                          />
                        <select
                          className="uk-select"
                          id="form-modelo-select"
                          name="modelo"
                          onChange={e => this.handleChange(e.target.value)}
                        >
                          <option value="nuevo">SELECCIONA MODELO*</option>

                          {this.state.modelos.map(item => (
                            <option key={item.node.id} value={item.node.slug}>
                              {item.node.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="uk-margin">
                      <label
                        className="uk-form-label uk-text-bold uk-text-left bsc-bright-blue"
                        htmlFor="form-version-select"
                      >
                        Versión del vehículo
                      </label>
                      <div className="uk-form-controls">
                        <div className="uk-inline-c">
                          <span
                            className="uk-form-icon uk-form-icon-flip fas fa-caret-down"

                          />
                          <select
                            className="uk-select"
                            id="form-version-select"
                            disabled={this.state.disabled}
                            onChange={e => this.versionChange(e.target.value)}
                          >
                            {this.state.version_modelo.length > 0 &&
                              this.state.version_modelo.map((items, key) => (
                                <option
                                  key={key}
                                  value={items.node.precio_version}
                                >
                                  {items.node.version_modelo}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end item group*/}
                <div>
                  <label
                    className="uk-form-label uk-text-bold uk-text-left bsc-bright-blue"
                    htmlFor="form-banco-text"
                  >
                    Banco
                  </label>

                  <div className="uk-form-controls">
                    <div className="uk-inline-c">
                      <span
                            className="uk-form-icon uk-form-icon-flip fas fa-caret-down"

                          />
                      <select
                        className="uk-select"
                        id="form-banco-select"
                        disabled={this.state.disabled}
                        onChange={e => this.bancoChange(e.target.value)}
                      >
                        <option>SELECCIONAR BANCO*</option>
                        {this.state.bancos.map(item => (
                          <option key={item.node.id} value={item.node.id}>
                            {item.node.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="uk-margin">
                    <label
                      className="uk-form-label uk-text-bold uk-text-left bsc-bright-blue"
                      htmlFor="form-tasa-select"
                    >
                      Tasa de interés anual
                    </label>
                    <div className="uk-form-controls">
                      <div className="uk-inline-c">
                        <span
                            className="uk-form-icon uk-form-icon-flip fas fa-caret-down"

                          />
                        <select
                          className="uk-select"
                          id="form-tasa-select"
                          disabled={this.state.disabled}
                          onChange={e => this.tasaChange(e.target.value)}
                        >
                          {this.state.interesList.length > 0 &&
                            this.state.interesList.map((items, key) => (
                              <option
                                key={key}
                                value={items.node.tasa_financiamiento}
                              >
                                {items.node.condicion_tasa}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="uk-margin">
                    <label
                      className="uk-form-label uk-text-bold uk-text-left bsc-bright-blue"
                      htmlFor="range"
                    >
                      Plazo de financiamiento
                    </label>
                    <div id="content-plazo" className="uk-form-controls">
                      <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                        {this.state.interesList.length > 0 &&
                          this.state.interesList.map(items =>
                            items.node.plazos_tasa.map((items, key) => (
                              <label
                                key={key}
                                className="uk-btn uk-btn-primary "
                              >
                                <input
                                  className="uk-radio"
                                  type="radio"
                                  name="plazo"
                                  defaultChecked={
                                    this.state.plazo + " Año" === items
                                  }
                                  onChange={this.plazoChange}
                                  value={items}
                                />
                                {items}
                              </label>
                            ))
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end item group*/}
              </div>
              {/* end uk-child-width*/}
            </div>
            {/* end uk-container*/}
          </div>
          {/* end uk-container*/}
        </form>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        chevrolet {
          chevrolets(last: 1000, where: { status: PUBLISH }) {
            edges {
              node {
                id
                slug
                title
                content
                featuredImage {
                  id
                  sourceUrl
                }
                versiones {
                  edges {
                    node {
                      precio_version
                      sku_version
                      version_modelo
                    }
                  }
                }
              }
            }
          }
          bancos(last: 1000, where: { status: PUBLISH }) {
            edges {
              node {
                id
                title
                tasaPorDefecto
                inicial
                financieros {
                  edges {
                    node {
                      condicion_tasa
                      tasa_financiamiento
                      plazos_tasa
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ chevrolet: { chevrolets, bancos } }) => (
      <Vehiculo chevrolets={chevrolets} bancos={bancos} />
    )}
  />
)
