import React from "react"

const MapFilters = ({ concesionarioList, onFilterClick }) => (
  <div
    id="concesionarioList"
    className="uk-margin uk-flex uk-flex-column uk-height-max-large"
  >
  <ul data-uk-accordion>
    {concesionarioList.map((item, key) => (
    <li key={key}>
        <a class="uk-accordion-title" href={`#{item.node.nombre}`} title={item.node.nombre} onClick={e => onFilterClick(item.node)}>
          <div
              className="uk-grid-small uk-flex-middle uk-grid"
              uk-grid="true"
            >
              <div className="uk-width-auto">
                <img
                  width="40"
                  height="40"
                  alt={item.node.nombre}
                  src={item.node.logo}
                  title={item.node.nombre}
                />
              </div>
              <div className="uk-width-expand">
                <h4 className=" uk-margin-remove-bottom">{item.node.nombre}</h4>
                <p className="uk-text-meta uk-margin-remove-top">
                  <time dateTime="2016-04-01T19:00">{item.node.contacto}</time>
                </p>
              </div>
          </div>
        </a>
        <div class="uk-accordion-content">
            <p>{item.node.direccion}</p>
            <p>{item.node.descripcion}</p>
            <hr />
          <a
            href={item.node.como_llegar}
            target="_blank"
            rel="noopener noreferrer"
            title={item.node.nombre}
            className="uk-button uk-button-secondary uk-width-1-1"
          >
            Como llegar
          </a>

        </div>
         <hr className="uk-divider" />
    </li>
        ))}
</ul>

  </div>
)

export default MapFilters
