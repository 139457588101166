/* eslint-disable */
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Form from "./form";
const config = require('../../../config/app')

class SolicitarPiezas extends React.Component {
  constructor(props) {
    super(props)
    console.log
    this.state = {
      requesId: false,
      alert: "",
      alertType: "uk-hidden",
      error: false,
      btnText: "ENVIAR",
      datautos: [],
      feedBackInput: {
        modelo: "",
        anio: "",
        chasis: "",
        titulo: "",
        apellido: "",
        cedula: "",
        celular: "",
        email: "",        
        name: "",
        contacto:"",
        version_modelo: "",
      },

      urlOrginForm: config.uff,
      title: "",
      sending: "",
      currentPrice: "",
      currentVercion: "",
      versiones: [
        {
          node: {
            precio_verion: "",
            version_modelo: "Versión del vehículo",
          },
        },
      ],
    }
    console.log("Props",this.props);
    this.vehiculoChange = this.vehiculoChange.bind(this)
    this.versionChange = this.versionChange.bind(this)
    this.SendEntries = this.SendEntries.bind(this)
    this.validateInput = this.validateInput.bind(this)
    // this.inputChange = this.inputChange.bind(this)
  }
  // inputChange(e) {
  //   //console.log(e)
  // }
  componentDidMount() {
    let uri = new URL(window.location)
    let slug = uri.search.replace('?','').split("&")


    let slugFiltered = {}
    
    slug.map(slugFilter => { 
      let slugSplitted = slugFilter.split("=")
      slugFiltered[slugSplitted[0]]= slugSplitted[1]
    })


    const autos = this.state.datautos
    const dataAuto = autos.filter(row => row.node.slug === slugFiltered.id )

 
    
    if (dataAuto.length === 1) {
      this.setState({
        requesId: dataAuto[0].node.title,
        title: dataAuto[0].node.title,
        currentPrice: dataAuto[0].node.versiones.edges[0].node.precio_version,
        currentVercion: slugFiltered.version,
        versiones: dataAuto[0].node.versiones.edges,
      })

      // this.vehiculoChange('vitara');
      
      document.querySelector('select[name="modelo"]').value =
        dataAuto[0].node.slug
      
        document.querySelector('select[name="version_modelo"]').value =
        slugFiltered.version


        
    }

    
    console.log("state", this.state);
  }

  vehiculoChange(e) {
    if (e !== "") {
      const dataAuto = this.state.datautos.filter(row => row.node.slug === e)
      

      this.setState({
        title: dataAuto[0].node.title,
        currentPrice: dataAuto[0].node.versiones.edges[0].node.precio_version,
        currentVercion: dataAuto[0].node.versiones.edges[0].node.version_modelo,
        versiones: dataAuto[0].node.versiones.edges,
      })
    } else {
      this.setState({
        title: "",
        currentPrice: "",
        currentVercion: "",
        versiones: [
          {
            node: {
              precio_verion: "",
              version_modelo: "Versión del vehículo",
            },
          },
        ],
      })
    }
  }

  versionChange(e) {
    
    let { value } = e.target
    const data = this.state.versiones.filter(
      row => row.node.version_modelo === value
    )

    this.setState({
      currentPrice: data[0].node.precio_version,
      currentVercion: data[0].node.version_modelo,
    })
  }

  SendEntries(dataFromForm) {
    if (typeof window !== "undefined") {
      // document.querySelector('#cotizarForm input[type=button]').setAttribute("disabled","disabled");
      const cotizarForm = Array.from(document.forms["cotizarForm"].elements)
      this.setState({btnText:"ENVIANDO..."})
      let data = { flexformdata: {} }

      cotizarForm.forEach(n =>
        n.type == "text" || n.type == "select-one" || n.type == "email"
          ? (data["flexformdata"][n.name] = n.value)
          : ""
      )
        
      this.validateInput(dataFromForm)

    }
  }

  validateInput(v) {
    let modelo, anio, chasis, titulo, apellido, cedula, celular, email, telefono, name, version_modelo
    let err = false

    v["modelo"] == "" ? (modelo = "uk-form-danger") : ""
    v["anio"] == "" ? (anio = "uk-form-danger") : ""
    v["chasis"] === "" ? (chasis = "uk-form-danger") : ""
    v["titulo"] === "" ? (titulo = "uk-form-danger") : ""
    v["name"] === "" ? (name = "uk-form-danger") : ""
    v["apellido"] === "" ? (apellido = "uk-form-danger") : ""
    v["email"] === "" ? (email = "uk-form-danger") : ""
    v["telefono"] === "" ? (telefono = "uk-form-danger") : ""
    v["celular"] === "" ? (celular = "uk-form-danger") : ""
    
    
    
    v["version_modelo"] == "Versión del vehículo"
      ? (version_modelo = "uk-form-danger")
      : ""

    Object.entries(v).forEach(element => {
      console.log("Entradas: ",element);
      if (element[1] === "" || element[1] === "Versión del vehículo") {
        err = false
        this.setState({
          btnText: "ENVIAR",
          alert: true,
          error: true,
          feedBackInput: {
            modelo: modelo,
            anio: anio,
            chasis: chasis,
            titulo: titulo,
            apellido: apellido,
            cedula: cedula,
            celular: celular,
            email: email,
            name: name,
            version_modelo: version_modelo,
          },
        })
      }
    })

    if (!err) {
      const datetime = Date.now();
      const raw = JSON.stringify({
        "flexformdata":
        {
          "FormCode": [[{ "value": "NWNhODhmYzMzNDg4Zg==" },
          { "datetime": datetime }, { "action": 1 }]], "modelo": [[{ "value": v["modelo"] },
          { "datetime": datetime }, { "action": 1 }]], "anio": [[{ "value": v["anio"] },
          { "datetime": datetime }, { "action": 1 }]], "chasis": [[{ "value": v["chasis"] },
          { "datetime": datetime }, { "action": 1 }]], "apellidos": [[{ "value": v["apellido"] },
          { "datetime": datetime }, { "action": 1 }]], "email": [[{ "value": v["email"] },
          { "datetime": datetime }, { "action": 1 }]], "telefono": [[{ "value": v["telefono"] },
          { "datetime": datetime }, { "action": 1 }]], "celular": [[{ "value": v["celular"] },
          { "datetime": datetime }, { "action": 1 }]], "contacto": [[{ "value": v["contacto"] },
          { "datetime": datetime }, { "action": 1 }]]
        }
      });

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      const requestOptions = {
        method: 'POST',
        'mode': 'cors',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      

      fetch(
        this.state.urlOrginForm +
          `/wp-json/OmdFlex_forms/v1/save_form/610456536894f`,
        {
            method: "POST",
            body: JSON.stringify(raw),
            mode: 'cors'
          }
      )
        .then(response => {
          // document.querySelector('#cotizarForm input[type=button]').removeAttribute('disabled');
          this.setState({
            alert: "Gracias, hemos recibido su solicitud.",
            alertType: "uk-alert-success",
            error: false,
            btnText:"ENVIAR",
            feedBackInput: {
              modelo: "",
              anio: "",
              chasis: "",
              titulo: "",
              apellido: "",
              cedula: "",
              celular: "",
              email: "",
              name: "",
              version_modelo: "",
            },
          })
          setInterval(() => {
             window.location.reload()
          }, 1000);
        })
        .catch(fail => {
          // document.querySelector('#cotizarForm input[type=button]').removeAttribute('disabled');
          this.setState({
            btnText: "ENVIAR", alertType: "uk-alert-danger",
            alertType: "uk-alert-danger",
            alert: "no hemos podido enviar su mensaje, intente más tarde"})
          console.log(fail)
        })
    }else{
      console.log("Paso?",err)
    }
  }

  render() {
    const handleValidationsForm = (e) => {
      e.preventDefault()
      console.log(e)
    }

    return (
      <div>
        <StaticQuery
          query={graphql`
            query {
              chevrolet {
                chevrolets(first: 1000, where: { status: PUBLISH }) {
                  edges {
                    node {
                      id
                      slug
                      title
                      content
                      
                      versiones {
                        edges {
                          node {
                            precio_version
                            sku_version
                            version_modelo
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            this.state.datautos = data.chevrolet.chevrolets.edges
            const dataAutos = data.chevrolet.chevrolets.edges
            console.log("this state=>",this.state);
            return (
              <div className="uk-container-small uk-container uk-margin-large uk-padding uk-padding-horizontal-remove">
                <Form SendEntries={this.SendEntries} modelo={this.state.feedBackInput.modelo} dataAutos={dataAutos} anio={this.state.feedBackInput.anio} chasis={this.state.feedBackInput.chasis} titulo={this.state.feedBackInput.titulo} name={this.state.feedBackInput.name} telefono={this.state.feedBackInput.telefono} celular={this.state.feedBackInput.celular} apellido={this.state.feedBackInput.apellido} email={this.state.feedBackInput.email} alert={this.state.alert} alertType={this.state.alertType}/>
              </div>
            )
          }}
        />
      </div>
    )
  }
}

export default SolicitarPiezas
